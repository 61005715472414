<template>
  <div>
    <Card>
      <template #body>
        <Table>
          <template #project="{ item }">
            {{ item.project.project_name }}
          </template>
          <template #employee="{ item }">
            {{ item.employee.full_name }}
          </template>
        </Table>
      </template>
    </Card>
    <!-- <Modal :data="modal_data"> </Modal> -->
  </div>
</template>
    <script>
import { mapActions, mapGetters } from "vuex";
import Card from "../Components/Card.vue";
import Table from "../Components/Table.vue";
export default {
  name: "Dashboard",
  components: {
    Card,
    Table,
  },
  data() {
    return {
      modal_data: {
        size: "600px",
        title: this.$i18n.t("role.Edit"),
      },

      btns: [
        {
          type: "icon",
          text: "edit",
          color: "bg-gradient-success",
          icon: "mdi-pencil",
          url: "/daily-entry/edit/",
          item: true,
          permission: "delaiy-entries-edit",
        },
        {
          type: "icon",
          text: "delete",
          color: "bg-gradient-success",
          icon: "mdi-delete",
          permission: "delaiy-entries-delete",
        },
      ],
      header: [
        {
          text: this.$i18n.t("project.project_name"),
          value: "project",
          align: "center",
          name: "project",
        },
        {
          text: this.$i18n.t("employee"),
          value: "employee",
          align: "center",
          name: "employee",
        },
        {
          text: this.$i18n.t("daily.start_actual_time"),
          value: "start_actual_time",
          align: "center",
        },

        {
          text: this.$i18n.t("daily.end_actual_time"),
          value: "end_actual_time",
          align: "center",
        },
        {
          text: this.$i18n.t("daily.count_hour"),
          value: "count_hour",
          align: "center",
        },
        {
          text: this.$i18n.t("daily.amount"),
          value: "amount",
          align: "center",
        },
        { text: this.$i18n.t("Action"), value: "btns", align: "center" },
      ],
      card: {
        title: this.$i18n.t("daily.daily_entry"),
        add_url: "/daily-entry/create",
        permission: "delaiy-entries-create",
      },
      load_excel: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["str_per"]),
    classLangBtn() {
      return {
        "mr-6": this.$vuetify.rtl,
        "ml-6": !this.$vuetify.rtl,
      };
    },
  },
  methods: {
    ...mapActions("daily_entry", ["getData"]),

    classBtn(val) {
      return this.$store.commit("table/SET_CHECK_PER", val);
    },
    set_data() {
      this.$store.commit("SET_CARD", this.card);
      this.$store.commit("SET_COLLECTION", "daily_entry");
      this.$store.commit("table/SET_LOADING", true);
      this.$store.commit("table/SET_HEADERS", this.header);
      this.$store.commit("table/SET_BTNS", this.btns);
      this.$store.commit("table/SET_PAGINATION", true);
    },
  },
  created() {
    console.log(this.$route.params.message);
    if (this.$route.params.message) {
      this.$store.commit("form/SET_NOTIFY", this.$route.params.message);
    }
  },
  mounted() {
    // this.getUsers();
    this.set_data();
    this.getData({ reset: true });
    document.title = this.$i18n.t("daily_entry");
  },
};
</script>
    